import SmoothDraggable from '~/util/smooth-draggable'
import { mapState, mapMutations } from 'vuex';
import { ControlNamespace, ControlMutationTypes } from '../../store/module/control';
import { PageNamespace } from '../../store/module/page';
import TransitionPageState from "../../data/enum/TransitionPageState";
import SmoothDraggableEvent from '../../util/smooth-draggable/SmoothDraggableEvent';
import RouteNames from '../../data/enum/RouteNames';

export default {
  name: 'AppNavigation',

  data() {
    return {
      progress: 0,
      toolSelected: '',
      openTools: false,
    }
  },

  mounted() {
    // this._smoothDraggable = new SmoothDraggable({
    //   parentElement: this.$refs.products,
    //   target: this.$refs.productList,
    //   subClassName: '.product-js',
    //   enabled: true,
    //   progressCallback: this.handleDragProgress
    // });

    // this._smoothDraggable.addEventListener(SmoothDraggableEvent.ELEMENT_CLICK, this.handleToolClick);
    // this._smoothDraggable.resize();
    window.addEventListener('resize', this.handleResize);

    const toolTitles = Array.prototype.slice.call(this.$el.querySelectorAll('.product-js'));
    toolTitles.map($title => {
      return new SplitText($title, {type: 'chars'});
    })
  },

  computed: {
    ...mapState(ControlNamespace, ['showMenu']),
    ...mapState(PageNamespace, ['transitionInState', 'page']),
  },

  methods: {
    ...mapMutations({
      changeShowMenu: ControlMutationTypes.SHOW_MENU,
      changeGoSection: ControlMutationTypes.GO_TO_SECTION,
    }),

    onPlusClick() {
      this.openTools = !this.openTools;
    },

    handleToolClick(slug) {
      this.toolSelected = slug;
      if(slug.indexOf('http') >= 0) {
        window.open(slug, 'blank');
      }
       else {
        this.$router.push({name: RouteNames.TOOLS, params:{slug}})
       }
    },
    handleDragProgress(progress) {
      // console.log((this._smoothDraggable.maxWidth * progress/100) % (this._smoothDraggable.maxWidth/this._smoothDraggable.countElements), Math.floor(progress % (100/this._smoothDraggable.countElements)));
      this.progress = progress;
    },
    handleResize() {
      // this._smoothDraggable.resize();
    },
    goTo(section) {
      document.querySelector(`#${section}`).scrollIntoView({
        behavior: 'smooth'
      });
      this.$router.push({path: `/#${section}`});
      this.changeShowMenu(false);
    }
  },

  watch: {
    // showMenu(value) {
    //   if(value) {
    //     this._showTl = new TimelineMax({onStart: this.handleStartShowAnimation});
    //     this._showTl.fromTo(this.$el, 2,{y: window.innerHeight}, {y: 0, force3D: true, ease: Expo.easeOut}, 0);
    //     this._showTl.fromTo(this.$refs.layers.querySelector('.bottom'), 1,  {top: "250%", rotation: 15}, { rotation: 0, top: 0},0);
    //     this._showTl.fromTo(this.$refs.layers.querySelector('.top'), 1, {top: "250%", rotation: -15}, { rotation: 0, top: 0, delay: .2}, 0);
    //     this._showTl.fromTo(this.$el.querySelector('.logo-js'), 1, {x: -window.innerWidth}, {x: 0, opacity: 1, ease: Expo.easeOut, delay: 1}, 0);
    //     this._showTl.staggerFromTo(this.$el.querySelectorAll('.menu-option'), 1, {scaleX: -2, rotationY: 90, y: window.innerHeight,  x: -window.innerWidth, opacity: 1}, {scaleX: 1, y: 0, x: 0,rotationY: 0, ease: Expo.easeOut, delay: 1.2}, 0.1, 0);     
    //     this.$el.querySelectorAll('.menu-option').forEach(($option, index) => {
    //       this._showTl.add(() => {
    //         $option.classList.remove('filled');
    //       }, (this._showTl.duration() - 1) + (0.1 * index));
    //     })
    //   } else {
    //     this._hideTl = new TimelineMax({onStart: this.handleHideAnimationStart, onComplete: this.handleHideAnimationComplete});
    //     this._hideTl.staggerTo(this.$el.querySelectorAll('.menu-option'), .25, {opacity: 0}, 0) 
    //     this._hideTl.to(this.$refs.layers.querySelector('.bottom'), 1, {rotation: 0, top: "-250%", delay: .2},0)
    //     this._hideTl.to(this.$refs.layers.querySelector('.top'), 1, {rotation: 0, top: "-250%"}, 0)
    //     this._hideTl.to(this.$el.querySelector('.logo-js'), .5, {opacity:0}, 0)
    //   }
    // },
    transitionInState(value) {
      switch(value) {
        case TransitionPageState.BEFORE_LEAVE: 
          if(this.showMenu) {
            // TweenMax.to(this.$el.querySelectorAll('.menu-option'), 1, {opacity: 0}, 0.1);     
          } else {
            // this.changeShowMenu(true);
          }
          break;
        case TransitionPageState.ENTER: 
          this.changeShowMenu(false)
          break;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
